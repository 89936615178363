import React from "react";
import Footer from "../components/Home/internal/Footer";
import ContactUsPage from "../components/ContactUs/ContactUsPage";
import Layout from "../components/Layout";

const ContactUs = () => (
  <Layout
    hideFooter
    image="https://imgix.cosmicjs.com/1ea993f0-de97-11eb-a12b-17ba28648e41-HomeOgImage.png"
    description="Bidddy is a tool that allows you to run comment driven auctions on social media. This provides businesses of any size a new way to grow sales and online presence."
    title="Bidddy | The World's First Automated Auctions for Social Media - Contact Us"
    canonical="https://bidddy.com/contact-us/"
    whiteBackground
  >
    <ContactUsPage />
    <Footer />
  </Layout>
);

export default ContactUs;
