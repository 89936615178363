import React from "react";
import { navigate } from "gatsby";
import styles from "./Home.module.scss";
import RoundedButton from "../../Buttons/RoundedButton";

const GoodVibesForm = () => (
  <div className={styles.goodVibesContainer}>
    <div className={styles.bottomCTA}>
      <h1>
        Questions? <a href="/contact-us/">CONTACT US.</a>
      </h1>
      <h1>Ready to auction?</h1>
      <RoundedButton
        onClick={() => navigate("/signup/")}
        style={{ width: "fit-content" }}
      >
        Try Bidddy for Free
      </RoundedButton>
      <p>600+ auctions powered by Bidddy in the past few months alone!</p>
    </div>
  </div>
);

export default GoodVibesForm;
