import React, { useState } from "react";
import axios from "axios";
import Imgix from "react-imgix";
import { Link } from "gatsby";
import { sendToast, encode } from "../../utils/helpers";
import RoundedButton from "../Buttons/RoundedButton";
import DefaultInput from "../Inputs/DefaultInput";
import styles from "./ContactUsPage.module.scss";
import GoodVibesForm from "../PageComponents/NewHome/GoodVibesForm";
import Tips from "../PageComponents/NewHome/Tips";

const FORM_NAME = "ContactUsForm";
const Bidddy101 =
  "https://imgix.cosmicjs.com/a738a4c0-4d5c-11ec-9940-53a088b36636-bidddy101New.png";

const ContactUsPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    if (email !== "" && message !== "" && subject !== "") {
      try {
        await axios.post(
          `/?${encode({
            "form-name": FORM_NAME,
            email,
            message,
            subject,
          })}`,
          null,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setSuccess(true);
        sendToast(
          "Thank you for your submission! We will get back to you as soon as we can.",
          { type: "success" }
        );
      } catch (err) {
        sendToast(
          "Sorry there was an error submitting your message. Please try again or contact us at hello@getbidddy.com.",
          { type: "error" }
        );
      }
    } else {
      sendToast(
        "Please fill out your email, subject and message before submitting",
        { type: "warning" }
      );
    }
  };

  return (
    <div className={styles.contactUsPageContainer}>
      <div className={styles.formContainer}>
        <h1>Contact Us!</h1>
        {!success && (
          <div className={styles.formContainerContent}>
            <h2>We&apos;d Love to Hear From You.</h2>
            <form
              className={styles.form}
              name={FORM_NAME}
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value={FORM_NAME} />
              <DefaultInput
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                type="email"
              />
              <DefaultInput
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
                type="text"
              />
              <textarea
                name="message"
                className={styles.defaultTextArea}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
              />
              <RoundedButton onClick={submitForm}>Send</RoundedButton>
            </form>
          </div>
        )}
        {success && (
          <p className={styles.successMessage}>Successfully sent message!</p>
        )}
      </div>
      <div className={styles.youMayAlsoLike}>
        <h4>You may also like...</h4>
        <div className={styles.youMayAlsoLikeSections}>
          <Link to="/learn/bidddy-101/" className={styles.bidddy101Card}>
            <Imgix
              src={`${Bidddy101}?auto=format`}
              width={439}
              height={323}
              htmlAttributes={{
                alt: "Bidddy 101",
                src: `${Bidddy101}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
            <p>Bidddy 101 - Your Guide To Getting Started On Bidddy.</p>
          </Link>
          <Link to="/learn/faq/" className={styles.mainTitle}>
            <h2>Support &amp; </h2>
            <h3>FAQ</h3>
          </Link>
          <Tips />
        </div>
        <GoodVibesForm />
      </div>
    </div>
  );
};

export default ContactUsPage;
