import React from "react";
import Imgix from "react-imgix";
import LazyLoad from "react-lazyload";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { navigate } from "gatsby-link";
import styles from "./Home.module.scss";

const BIDFOOT_SKATEBOARD =
  "https://imgix.cosmicjs.com/aa482c80-c706-11eb-ba89-e7f98c8c358b-BidfootSkateboard.png";
const Tips = () => {
  const handleTipsClick = () => {
    navigate("/tips/5-tips-for-running-successful-instagram-auctions/");
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleTipsClick}
      className={styles.tipsContainer}
      onClick={handleTipsClick}
    >
      <div className={styles.tips}>
        <div className={styles.mainBlock}>
          <h2>Tips</h2>
          <h3>For a Better Auction</h3>
        </div>
        <div className={styles.characterContainer}>
          <LazyLoad once height={326}>
            <Imgix
              src={`${BIDFOOT_SKATEBOARD}?auto=format`}
              height={326}
              width={254}
              htmlAttributes={{
                alt: "BidFoot Connect to instagram",
                src: `${BIDFOOT_SKATEBOARD}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default Tips;
